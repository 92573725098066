import React from 'react';

import './MangaSidebar.scss';

const MangaSidebar = props => {
  // console.log('[MangaSidebar]', props)
  const linkStyle = {
    backgroundImage: `url('https://ergoreader.altervista.org/images/banners/banner-${props.manga.id}.png')`
  };
  return !props.manga.hidden ? (
    <li className="SingleSerie" data-serie-id={props.manga.id}>
      <button className="btn" style={linkStyle} onClick={props.selectManga}>
        <p>
          {props.manga.name}
          <br />
          Capitolo {props.manga.end} {props.manga.status === 'completed' ? '- END': ''}
        </p>
      </button>
      {props.manga.status === 'dropped' && <p className="dropped-banner">Droppato</p>}
    </li>
  ) : null;
};

export default MangaSidebar;
