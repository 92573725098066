import React from 'react';

import './LoadingOverlay.scss';
import logo from '../../images/ergo-logo-plain.png';

const loadingOverlay = props => {
  const OverlayClasses = [
    'Loading-Overlay',
    props.hasError ? 'ProviderError' : null
  ];
  return (
    <div className={OverlayClasses.join(' ')}>
      <div className="Content">
        <img
          src={logo}
          alt="Ergo Team - Loading"
          className={!props.hasError ? 'Pulse' : null}
        />
        {props.hasError ? (
          <React.Fragment>
            <p>Ci scusiamo per il disagio, c'è stato un errore di servizio</p>
            <p>
              Mandaci un messaggio sulla pagina{' '}
              <a
                href="https://www.facebook.com/Ergoteam/?fref=ts"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>{' '}
              oppure{' '}
              <a href="https://ergoreader.altervista.org/contact.php">
                contattaci
              </a>
              , risolveremo il problema il prima possibile
            </p>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default loadingOverlay;
