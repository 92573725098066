import imageError from '../images/image-error.png';
const errorALT = 'Oh no! Qualcosa è andato storto... Prova a ricaricare la pagina e se il problema persiste non esitare e contattaci!';

const lazyLoad = (reset = false) => {
  const lazyloadVanilla = () => {
    // Test if image is in the viewport
    const isImageInViewport = img => {
      const rect = img.getBoundingClientRect();

      return rect.top <= window.innerHeight + 100;
    };

    // lazyloadVanilla function
    const lazyloadVanillaLoader = () => {
      const lazyImagesList = document.querySelectorAll('img[data-src]');
      const lazyImagesArray = [...lazyImagesList];

      lazyImagesArray.slice(0, 3).forEach(image => {
        if (isImageInViewport(image)) {
          if (image.getAttribute('data-src') !== null) {
            image.setAttribute('data-loading', true);

            // Preload the new image somewhere in the DOM
            const newImage = new Image();
            newImage.setAttribute('src', image.getAttribute('data-src'));
            document
              .getElementsByClassName('preloaders')[0]
              .appendChild(newImage);
            image.removeAttribute('data-src');

            // On load of the-somewhere-image replace the src to the current one
            // !Disable cache if images are loaded twice!
            newImage.onload = evt => {
              image.setAttribute('src', newImage.getAttribute('src'));
              image.removeAttribute('data-loading');
            };
            newImage.onerror = evt => {
              image.setAttribute('src', imageError);
              image.setAttribute('alt', errorALT);
              image.removeAttribute('data-loading');
            };
          }
          image.setAttribute('data-loaded', true);
        }
      });

      // Remove event listeners if all images are loaded
      if (document.querySelectorAll('img[data-src]').length === 0) {
        window.removeEventListener('scroll', lazyloadVanillaLoader);
      }
    };

    window.removeEventListener('scroll', lazyloadVanillaLoader);

    // This is the magic
    window.addEventListener('scroll', lazyloadVanillaLoader, true);
  };
  return lazyloadVanilla();
}

export default lazyLoad;