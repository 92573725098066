import React from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet'

import './MangaDetails.scss';

const MangaDetails = props => {

  const chooseMangaRef = React.useRef();
  const mangas = useSelector(state => state.mangas)

  const actionHandler = (action, id) => {
    const chapter = chooseMangaRef.current.value.replace(/[^0-9\.]+/g, '');
    ReactGA.event({
      category: 'MangaDetails',
      action: action,
      label: chapter
    });

    props.history.push({
      pathname: `/${id}/${action === 'download' ? 'download' : chapter}`
    });
  }

  // console.log('[MangaDetails][Render]', this.props);
  const manga = mangas.find(obj => obj.id === props.match.params.id);
  let mangaHTML = null;

  if (manga) {
    let select = [];
    for (let i = manga.start; i <= manga.end; i++) {
      select.push(parseInt(i < 10 ? '0' + i : i, 10));
    }

    if (manga['extra'].length) {
      select = [...select, ...manga['extra']];
      // Some sort of magic to order the array numerically using .sort()
      select.sort((a, b) => {
        return a - b;
      });
    }

    const cover = require(`../../../images/${manga.id}/cover.png`);

    let collaborations = '';

    if (manga.collaborations) {
      collaborations = manga.collaborations.map(col => {
        return (<p key={col.details} className="collaboration">
          {col.details}
          {col.link ? <a href={col.link} target="_blank" rel="noopener noreferrer">
            {col.name}
            <span>
              <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
            </span>
          </a> : null}
        </p>);
      })
    }

    mangaHTML = (
      <div className="MangaDetails">
        <Helmet>
          <title>{manga.name}</title>
          <link rel="canonical" href={`http://ergoreader.altervista.org/r/${props.match.params.id}`} />
        </Helmet>
        <h2 className="title">{manga.name}</h2>
        <figure>
          <img src={cover} alt="" />
        </figure>
        {collaborations}
        {!manga.external ? (
          <form className="MangaForm">
            <div className="form form-group">
              <label htmlFor="choose-manga" className="field-label">
                <span>Scegli capitolo</span>
                <select
                  className="form-control"
                  id="choose-manga"
                  name="manga"
                  ref={chooseMangaRef}
                >
                  <option disabled>Scegli...</option>
                  {select.reverse().map(el => (
                    <option key={`${manga.id}-${el}`}>
                      {el < 10 ? '0' + el : el}
                      {manga.status === 'completed' && el.toString() === manga.end ? ' - END' : ''}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="my-2">
              <button
                type="button"
                className="btn bttn bttn-navy"
                onClick={() => actionHandler('read', manga.id)}
              >
                Leggi Online
              </button>
            </div>
            <div className="my-2">
              <button
                type="button"
                className="btn bttn bttn-olive"
                onClick={() => actionHandler('download', manga.id)}
              >
                Scarica
              </button>
            </div>
          </form>
        ) : (
            <a
              href={manga.external}
              className="btn bttn bttn-blue BtnExternal"
              target="_blank"
              rel="noopener noreferrer"
            >
              Vai al sito
              <span>
                <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
              </span>
            </a>
          )}
      </div>
    );
  } else {
    mangaHTML = <Redirect to="404" />
  }

  return mangaHTML;

}

export default MangaDetails;
