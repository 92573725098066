import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookOpen } from '@fortawesome/free-solid-svg-icons';

import logo from '../../../images/ergo-logo.gif';
import './SidebarHeader.scss';

const SidebarHeader = props => (
  <div className="SidebarHeader">
    <h1>
      <Link to="/" aria-label="Vai all'homepage">
        <img src={logo} alt="Ergo Team Reader" />
      </Link>
    </h1>
    <button
      type="button"
      className="SidebarMenuToggler btn"
      onClick={props.toggleMenu}
      aria-label="Apri/Chiudi menu"
    >
      <FontAwesomeIcon icon={!props.menu ? faBook : faBookOpen} />
    </button>
  </div>
);

export default SidebarHeader;
