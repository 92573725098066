import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';

import MangaSidebar from './mangaSidebar/mangaSidebar';
import SidebarHeader from './sidebarHeader/sidebarHeader';
import SidebarFooter from './sidebarFooter/sidebarFooter';
import './Sidebar.scss';

const Sidebar = props => {

  const [menu, setMenu] = useState(false)
  const mangas = useSelector(state => state.mangas)

  const toggleMenuHandler = () => {
    setMenu(!menu)
  }

  const selectManga = (evt, id) => {
    evt.preventDefault();
    ReactGA.event({
      category: 'Sidebar',
      action: 'Select manga',
      label: id
    });
    setMenu(false)
    props.history.push('/' + id);
  }

  const removeHiddenManga = mangas.filter(el => !el.hidden);
  const activeManga = removeHiddenManga.filter(el => el.status === 'active');
  const completedManga = removeHiddenManga.filter(el => el.status === 'completed');
  const droppedManga = removeHiddenManga.filter(el => el.status === 'dropped');
  const parsableMangas = [...activeManga, ...completedManga, ...droppedManga];

  const SideBarClasses = ['Sidebar', menu ? 'menu-visible' : null];

  return (
    <div className={SideBarClasses.join(' ')}>
      {mangas.length <= 0 ? (
        'Loading...'
      ) : (
          <nav>
            <SidebarHeader
              menu={menu}
              toggleMenu={() => toggleMenuHandler()}
            />
            <div className="SidebarContentWrapper">
              <ul className="list-unstyled">
                {parsableMangas.map(obj => {
                  return (
                    <MangaSidebar
                      key={obj.id}
                      manga={obj}
                      selectManga={evt => selectManga(evt, obj.id)}
                    />
                  );
                })}
              </ul>
            </div>
            <SidebarFooter />
          </nav>
        )}
    </div>
  );

}

export default withRouter(Sidebar);
