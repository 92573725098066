import * as actionTypes from '../actions/actionTypes';

const initialState = {
  mangas: [],
  providerError: false,
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MANGAS:
      return {
        ...state,
        mangas: action.payload,
        isLoading: false
      };
    case actionTypes.PROVIDER_ERROR:
      return {
        ...state,
        providerError: true,
        isLoading: false
      };
    case actionTypes.FETCH_MANGAS_START:
      return {
        ...state,
        isLoading: true
      }
    default:
      return state;
  }
};

export default reducer;
