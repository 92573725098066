import React from 'react';
import arrow from '../../images/arrow.png';

import './Landing.scss';

const Landing = () => {
  return (
    <div className="Landing">
      <img src={arrow} alt="" />
      <p>Scegli il manga da leggere</p>
    </div>
  );
};

export default Landing;
