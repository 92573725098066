import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const Page404 = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h2 className="title">404</h2>
      <p>Pagina non trovata</p>
      <FontAwesomeIcon icon={faExclamationCircle} size="3x" />
    </div>
  )
}

export default Page404;