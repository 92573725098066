import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

import './BackToTop.scss';

const BackToTop = props => {

  const [isButtonVisible, setButtonVisibility] = useState(false);
  const [ElementThatScrolls, setScrollableElement] = useState(null);

  useEffect(() => {
    if (props.container) {
      const ElementThatScrolls = document.querySelector(`.${props.container}`);
      setScrollableElement(ElementThatScrolls);
      ElementThatScrolls.addEventListener('scroll', () => handleScroll(ElementThatScrolls), true)
      return () => ElementThatScrolls.removeEventListener('scroll', () => handleScroll(ElementThatScrolls));
    }
  }, [])

  const handleScroll = target => {
    if(target){
      setButtonVisibility(target.scrollTop > 600);
    }
  }

  const BackToTopClasses = ['BackToTop', isButtonVisible ? 'visible' : '']
  return (
    <div className={BackToTopClasses.join(' ')}>
      <button className="btn bttn" aria-label="Torna all'inizio della pagina" onClick={() => ElementThatScrolls.scrollTop = 0}>
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </div>
  )
}

export default BackToTop;