import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';

import MangaDetails from './mangaDetails/MangaDetails';
import MangaPages from './mangaPages/MangaPages';
import Downloader from '../../components/downloader/Downloader';
import Landing from '../../components/landing/Landing';
import Page404 from '../../components/404/404';

import './MangaReader.scss';

const Reader = props => {
  const onError = props.onError;
  return (
    <div className="MangaReader">
      <Switch>
        <Route path="/404" exact component={Page404} />
        <Route path="/:id" exact component={MangaDetails} />
        <Route
          path="/:id/download"
          exact
          component={props => <Downloader {...props} />}
        />
        <Route
          path="/:id/:chapter"
          render={props => <MangaPages {...props} onError={() => onError()} />}
        />
        <Route component={Landing} />
      </Switch>
    </div>
  );
};

export default withRouter(Reader);
