import React from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LazyLoad from '../../shared/LazyLoad';

import './Downloader.scss';

const downloaderLink = 'https://ergodownloader.altervista.org/files';

const Downloader = props => {

  const mangas = useSelector(state => state.mangas);
  const manga = mangas.find(obj => obj.id === props.match.params.id);

  const sendGAevents = (evt, action) => {
    const chapter = evt.currentTarget.getAttribute('data-ga');
    ReactGA.event({
      category: 'Downloader',
      action: `${manga.id} ${action}`,
      label: chapter
    });
  }

  let mangaHTML = null;

  if (manga) {
    const volumeStart = parseInt(manga['downloader-volume-start'], 10);
    const volumeEnd = parseInt(manga['downloader-volume-end'], 10);
    const mangaVolumes =
      volumeEnd >= volumeStart
        ? Array.from(
          { length: volumeEnd - volumeStart + 1 },
          (x, i) => volumeStart + i
        )
        : null;

    const singleStart = parseInt(manga['downloader-single-start'], 10);
    const singleEnd = parseInt(manga['downloader-single-end'], 10);
    let mangaSingles =
      singleEnd >= singleStart
        ? Array.from(
          { length: singleEnd - singleStart + 1 },
          (x, i) => singleStart + i
        )
        : null;

    if (manga['downloader-single-extra'].length) {
      mangaSingles = [...mangaSingles, ...manga['downloader-single-extra']];
      // Some sort of magic to order the array numerically using .sort()
      mangaSingles.sort((a, b) => {
        return a - b;
      });
    }

    const volumeClasses = [
      'Volumes',
      !(manga['downloader-single-available'] && mangaSingles) ? 'Full' : ''
    ];
    const volumeListClasses = [
      'list-unstyled',
      mangaVolumes && mangaVolumes.length && mangaVolumes.length >= 2
        ? 'grid'
        : null
    ];
    const singleClasses = ['Singles', !mangaVolumes ? 'Full' : ''];

    const placeholder = require(`../../images/${manga.id}/placeholder.png`);

    LazyLoad();

    mangaHTML = (
      <div className="Downloader row no-gutters">
        <Helmet>
          <title>{`${manga.name} | Download`}</title>
          <link rel="canonical" href={`http://ergoreader.altervista.org/r/${props.match.params.id}/download`} />
        </Helmet>
        <div className={volumeClasses.join(' ')}>
          {mangaVolumes ? (
            <React.Fragment>
              <h2 className="title">{manga.name}</h2>
              <ul className={volumeListClasses.join(' ')}>
                {mangaVolumes.map((el, index) => {
                  const normalizedEl = (el < 10 ? '0' : null) + el;
                  const pattern = `${downloaderLink}/${manga.id}`;
                  const src = `${pattern}/cover_${normalizedEl}.png`;
                  const link = `${pattern}/ErgoTeam_${manga['downloader-volume-prefix']}_volume_${normalizedEl}.zip`;
                  return (
                    <li key={el}>
                      <div className="center">
                        <a href={link}
                          data-ga={normalizedEl}
                          onClick={evt => sendGAevents(evt, 'volume')}
                          download
                          rel="noopener noreferrer"
                        >
                          <img src={index <= 6 ? src : placeholder}
                            data-src={index <= 6 ? null : src}
                            alt=""
                          />
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          ) : null}
        </div>
        <div className={singleClasses.join(' ')}>
          {manga['downloader-single-available'] && mangaSingles ? (
            <React.Fragment>
              <h3 className="title">
                Capitolo singoli non ancora in formato volume
                </h3>
              <ul className="list-unstyled">
                {mangaSingles.reverse().map(el => {
                  const normalizedEl = el < 10 ? '0' + el : el;
                  const pattern = `${downloaderLink}/${manga.id}`;
                  const link = `${pattern}/ErgoTeam_${manga['downloader-volume-prefix']}_capitolo_${normalizedEl}.zip`;
                  const linkText = `Capitolo - ${normalizedEl}`;
                  return (
                    <li key={el}>
                      <a href={link}
                        data-ga={normalizedEl}
                        onClick={evt => sendGAevents(evt, 'single')}
                        download
                        rel="noopener noreferrer"
                      >
                        {linkText}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          ) : null}
        </div>
        <div className="preloaders d-none" />
      </div>
    );
  } else {
    mangaHTML = <Redirect to="/404" />
  }
  
  return mangaHTML;

}

export default Downloader;
