import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faBeer } from "@fortawesome/free-solid-svg-icons";

import "./SidebarFooter.scss";

const SidebarFooter = props => {
  const submitPaypalHandler = evt => {
    evt.preventDefault();
    const form = document.getElementById("PayPalForm");

    // Inject position input
    const xInput = form.querySelector('[name="submit.x"]');
    xInput.setAttribute("value", evt.screenX);
    const yInput = form.querySelector('[name="submit.y"]');
    yInput.setAttribute("value", evt.screenY);

    // Submit
    form.submit();
  };

  return (
    <div className="SidebarFooter">
      <ul className="list-unstyled d-flex">
        <li className="col-4">
          <a
            href="https://www.facebook.com/Ergoteam/?fref=ts"
            target="blank"
            title="Seguici su Facebook"
            aria-label="Seguici su Facebook"
            rel="noopener nofollow"
          >
            <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
          </a>
        </li>
        <li className="col-4">
          <a
            href="https://ergoreader.altervista.org/contact.php"
            aria-label="Contattaci"
            title="Contattaci"
          >
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>
        </li>
        <li className="col-4">
          <a
            aria-label="Fai una donazione con PayPal"
            title="Fai una donazione con PayPal"
            role="button"
            tabIndex="0"
            onClick={submitPaypalHandler}
          >
            <FontAwesomeIcon icon={faBeer} size="2x" />
            <form
              id="PayPalForm"
              method="POST"
              action="https://www.paypal.com/cgi-bin/webscr"
              className="d-none"
              target="_blank"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="K6BQDECP75S6J"
              />
              <input type="hidden" name="submit.x" />
              <input type="hidden" name="submit.y" />
            </form>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SidebarFooter;
