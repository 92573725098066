import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet'

import * as mangasAction from '../../store/actions';
import LoadingOverlay from '../../components/loadingOverlay/loadingOverlay';
import Sidebar from '../../components/sidebar/Sidebar';
import ScrollToTop from '../../components/scrollToTop/ScrollToTop';
import MangaReader from '../mangaReader/MangaReader';
// import Feed from '../feed/Feed';

import '../../styles/index.scss'
import './Reader.scss';

let bodyClasses = ['Async-Update'];

class Reader extends Component {
  constructor(props) {
    super(props);
    const GoogleID = process.env.NODE_ENV !== 'development' ? 'UA-47580759-6' : null;
    ReactGA.initialize(GoogleID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidMount() {
    // console.log('[mainReader][componentDidMount]', this.props);

    this.props.onInitMangas();
  }

  shouldComponentUpdate() {
    // console.log('[mainReader][shouldComponentUpdate]', this.props);
    // this means: if mangas are not present update the component 'cause they are needed
    return this.props.mangas.length === 0;
  }

  componentDidUpdate() {
    // console.log('[mainReader][componentDidUpdate]', this.props);
  }

  ServerErrorHandler() {
    // console.log('ServiceError');
    this.props.onProviderErrorOccurred();
    this.forceUpdate();
  }

  render() {
    if (this.props.isLoading) {
      bodyClasses = ['Async-Update'];
    } else {
      bodyClasses = ['No-Async-Update'];
    }
    if (this.props.providerError) {
      bodyClasses = ['Async-Update'];
    }
    document.body.classList.value = bodyClasses.join(' ');

    return (
      <React.Fragment>
        <Helmet
          defaultTitle="Ergo Team Reader"
          titleTemplate="%s | Ergo Team Reader">
          <link rel="canonical" href="http://ergoreader.altervista.org/r/" />
          <meta name="description" content="Leggi manga online tradotti in italiano offerti dall'Ergo Team come Yamada-kun, Angel Beats e Masamune Revenge"/>
        </Helmet>
        <LoadingOverlay hasError={this.props.providerError} />
        {this.props.mangas.length ? (
          <main className="Reader">
            <BrowserRouter basename="/r/">
              <ScrollToTop />
              <React.Fragment>
                <Sidebar />
                <MangaReader
                  onError={() => this.ServerErrorHandler()}
                />
              </React.Fragment>
            </BrowserRouter>
            {/* <Feed /> */}
          </main>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    mangas: state.mangas,
    isLoading: state.isLoading,
    providerError: state.providerError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitMangas: () => dispatch(mangasAction.initMangas()),
    onProviderErrorOccurred: () => dispatch(mangasAction.providerError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reader);
