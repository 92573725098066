// import { domain } from '../../shared/herokuEnv'

import * as actionTypes from './actionTypes';
// import data from '../../config.json';
import manga from './manga.json'

export const setMangas = mangas => {
  return {
    type: actionTypes.SET_MANGAS,
    payload: mangas
  };
};

export const providerError = () => {
  return {
    type: actionTypes.PROVIDER_ERROR
  };
};

export const fetchMangasStart = () => {
  return {
    type: actionTypes.FETCH_MANGAS_START
  };
};

export const initMangas = () => {
  return dispatch => {
    dispatch(fetchMangasStart());
    dispatch(setMangas(manga))
    // fetch(`${domain}/manga`)
    //   .then(resp => resp.json())
    //   .then(response => {
    //     dispatch(setMangas(response));
    //   })
    //   .catch(error => {
    //     console.log('error', error);
    //     dispatch(providerError());
    //   });
  };
};
