import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducer from './store/reducers/reducer';
import Reader from './containers/reader/Reader';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
const app = (
    <Provider store={store}>
        <Reader />
    </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(app);
