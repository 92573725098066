import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import LazyLoad from '../../../shared/LazyLoad';
import BackToTop from '../../../components/backToTop/BackToTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

import './MangaPages.scss';

const filesPath = 'https://ergoreader.altervista.org/files';
let placeholder;

const MangaPages = props => {

  const [pages, setPages] = useState([]);
  const [firstChapter, setFirstChapter] = useState(false);
  const [lastChapter, setLastChapter] = useState(false);
  const MangaPagesRef = React.createRef();
  const [mangaName, setMangaName] = useState('');
  const mangas = useSelector(state => state.mangas)

  useEffect(() => {
    const currentManga = mangas.find(obj => obj.id === props.match.params.id);

    if (currentManga) {
      const mangaId = currentManga.id;
      const mangaChapter = props.match.params.chapter;
      setMangaName(currentManga.name);

      const normalizedChapter =
        mangaChapter < 10 && mangaChapter.length < 2
          ? '0' + mangaChapter
          : mangaChapter;
      const pagesURL = `https://ergoreader.altervista.org/counter.php?folder=./files/${mangaId}/${normalizedChapter}`;
      fetch(pagesURL)
        .then(resp => resp.json())
        .then(response => {
          const numberOfPages = response;
          const pages = [];
          for (let i = 1; i <= numberOfPages; i++) {
            const normalizedPage = i < 10 ? '0' + i : i;
            pages.push(
              `${filesPath}/${mangaId}/${normalizedChapter}/${normalizedPage}.png`
            );
          }
          if (pages.length) {
            placeholder = require(`../../../images/${mangaId}/placeholder.png`);
            setLastChapter(currentManga.end === mangaChapter);
            setFirstChapter(currentManga.start === mangaChapter);
            setPages(pages);
            LazyLoad();
          } else {
            props.history.push({
              pathname: '/404'
            });
          }
        })
        .catch(error => {
          props.onError();
        });
    } else {
      props.history.push({
        pathname: '/404'
      });
    }

  }, [props.match.params.chapter]);

  const goToChapter = action => {
    const mangaChapter = parseInt(props.match.params.chapter, 10);
    const modifier = action === 'next' ? +1 : -1;
    const normalizedChapter =
      mangaChapter + modifier < 10 && (mangaChapter + 1).length < 2
        ? '0' + mangaChapter + modifier
        : mangaChapter + modifier;

    // Trick to not have the pages loaded once a new chapter is requsted
    const scrollTopPromise = new Promise((resolve, reject) => {
      MangaPagesRef.current.scrollTop = 0;

      const interval = setInterval(() => {
        if (MangaPagesRef.current.scrollTop === 0) {
          clearInterval(interval)
          resolve()
        }
      }, 50);
    });

    scrollTopPromise.then(() => {
      props.history.push({
        pathname: `/${props.match.params.id}/${normalizedChapter}`
      });
    });
    // Ends here
  }

  // console.log('[MangaPages][Render]', props);
  return (
    <div className="MangaPages" ref={MangaPagesRef}>
      <Helmet>
        <title>{`${mangaName} | ${props.match.params.chapter}`}</title>
        <link rel="canonical" href={`http://ergoreader.altervista.org/r/${props.match.params.id}/${props.match.params.chapter}`} />
      </Helmet>
      <ul className="list-unstyled mx-auto">
        {pages.length ? (
          pages.map((el, index) => {
            // const pagesLength = pages.length;
            let ListItem = <li key={el}>
              <img
                src={index <= 1 ? el : placeholder}
                data-src={index <= 1 ? null : el}
                alt=""
              />
            </li>

            return ListItem
          })
        ) : (
            <React.Fragment>
              <li>
                <img src={placeholder} alt="" className="placeholder" />
              </li>
              <li>
                <img src={placeholder} alt="" className="placeholder" />
              </li>
            </React.Fragment>
          )}
      </ul>
      <div className="row ChapterButtons">
        <button className="ChapterButton prev" disabled={firstChapter} data-text="Capitolo precedente" onClick={() => goToChapter('prev')}>
          <FontAwesomeIcon icon={faChevronCircleLeft} size="2x" />
          <span className="sr-only">Capitolo precedente</span>
        </button>
        <button className="ChapterButton next" disabled={lastChapter} data-text="Capitolo successivo" onClick={() => goToChapter('next')}>
        <FontAwesomeIcon icon={faChevronCircleRight} size="2x" />
          <span className="sr-only">Capitolo successivo</span>
        </button>
      </div>
      <div className="preloaders d-none" />
      <BackToTop container="MangaPages" />
    </div>
  );

}


export default MangaPages;
